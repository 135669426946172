/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { graphql } from "gatsby"
import {
  GatsbyImage,
  StaticImage,
  getImage,
  withArtDirection,
} from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"
import Panel from "../components/panel/panel"
import Layout from "../components/layout/layout"
import Link from "../components/link/link"
import Metadata, { buildSocialImage } from "../components/metadata/metadata"
import SquareList from "../components/list-square/list-square"

export default function LearningLibraries({ data }) {
  if (!data) return null
  const socialImage = buildSocialImage({
    title: data.imageSocial.name,
    ...getImage(data.imageSocial),
  })
  return (
    <Layout>
      <Metadata
        title="Learning Catalog"
        // description="Moth+Flame is an award-winning developer of immersive training technology, pioneering the next decade of virtual reality learning for the private and public sector."
        // image={socialImage}
      />
      <Panel sx={{ pt: ["3rem", "6rem", "8rem"] }}>
        <Themed.h1
          className="scaleText"
          sx={{
            gridColumn: "1 / -1",
            borderBottom: "solid 3px #dee5e8",
            pb: "4rem",
          }}
        >
          Learning Catalog
        </Themed.h1>
      </Panel>
      {data.libraries.nodes.map(libraryItem => {
        const library = {
          ...libraryItem.data,
          uid: libraryItem.uid,
        }

        const modules = library.modules.filter(({ module }) =>
          Boolean(module.document?.uid)
        )

        return (
          <Panel
            key={library.uid}
            sx={{
              py: ["4rem"],
            }}
          >
            <Link
              to={`/learning-libraries/${library.uid}`}
              sx={{ gridColumn: ["1 / -1", "span 2"] }}
            >
              <GatsbyImage
                image={library.icon.gatsbyImageData}
                alt={library.title.text}
                sx={{
                  border: "solid 2px #eff1f2",
                  borderRadius: "50%",
                  bg: "#eff1f2",
                  maxWidth: [null, "90%", "80%"],
                }}
              />
            </Link>
            <Link
              to={`/learning-libraries/${library.uid}`}
              sx={{
                gridColumnStart: ["1", "3"],
                gridColumnEnd: ["-1", null, "span 8"],
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <Themed.h2
                sx={{ fontSize: ["2rem", null, "3.6rem"], mb: "1rem" }}
              >
                {library.title.text}
              </Themed.h2>
              <Themed.p
                sx={{
                  mb: "2rem !important",
                  fontSize: ["1.4rem", "1.6rem", "2rem"],
                  lineHeight: 1.6,
                }}
              >
                {library.landing_page_description.text}
                <strong
                  sx={{
                    fontWeight: 600,
                    color: "inherit",
                    ml: "0.5rem",
                    textDecoration: "underline",
                  }}
                >
                  Learn more
                </strong>
              </Themed.p>
            </Link>
            <div
              sx={{
                gridColumnStart: ["1", "unset"],
                gridColumnEnd: ["-1", "span 2"],
                gridRowStart: [null, null, "2"],
                fontSize: "1.3rem",
                lineHeight: 1.5,
                pr: [null, "3rem"],
                pt: [null, "3rem"],
                strong: {
                  fontWeight: 600,
                },
                p: {
                  mb: ["2rem", "2rem", "2rem"],
                },
              }}
            >
              <PrismicRichText
                field={library.benefits.richText}
                components={{
                  paragraph: props => <Themed.p key={props.key} {...props} />,
                }}
              />
            </div>
            {(modules.length > 0 || library.modules_coming_soon) && (
              <ul
                sx={{
                  gridColumnStart: ["1", "3"],
                  gridColumnEnd: ["-1", null, "span 9"],
                  display: "grid",
                  gridTemplateColumns: [
                    "repeat(1, 1fr)",
                    "repeat(2, 1fr)",
                    "repeat(4, minmax(0, 1fr))",
                  ],
                  gap: ["5rem", null, "2rem"],
                  m: 0,
                  mb: "3rem",
                  listStyle: "none",
                  bg: "bgGray",
                  borderRadius: "1.6rem",
                  px: ["2.4rem", null, "3rem"],
                  py: "3rem",
                  wordWrap: "anywhere",
                }}
              >
                {modules.map(({ module }) => {
                  const { title, description, thumbnail_image } =
                    module.document?.data ?? {}
                  return (
                    <li key={module.document?.uid}>
                      <Link
                        to={`/learning-libraries/${library.uid}/${module.document?.uid}`}
                        sx={{
                          lineHeight: 1.5,
                          textDecoration: "none",
                          color: "inherit",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {thumbnail_image?.gatsbyImageData && (
                          <div
                            sx={{
                              mb: "2rem",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              img: { borderRadius: "0.8rem" },
                              aspectRatio: [null, "16 / 9"],
                              //width: ["14.7rem", "20rem"],
                              // height: ["14.7rem", null, "17rem"],
                            }}
                          >
                            <GatsbyImage
                              image={thumbnail_image.gatsbyImageData}
                              alt={title?.text}
                              sx={{
                                aspectRatio: ["16 / 9"],
                                height: "100%",
                              }}
                            />
                          </div>
                        )}
                        <div sx={{ mt: "auto" }}>
                          <strong
                            sx={{
                              display: "inline-block",
                              mb: "0.5rem",
                            }}
                          >
                            {title?.text}
                          </strong>
                          <div
                            sx={{
                              fontSize: "1.4rem",
                              "p:last-of-type": {
                                mb: 0,
                              },
                            }}
                          >
                            <PrismicRichText
                              field={description?.richText}
                              components={{
                                paragraph: props => (
                                  <Themed.p key={props.key} {...props} />
                                ),
                                list: props => (
                                  <SquareList key={props.key} {...props} />
                                ),
                              }}
                            />
                          </div>
                        </div>
                      </Link>
                    </li>
                  )
                })}
                {library.modules_coming_soon && (
                  <li>
                    <div
                      sx={{
                        lineHeight: 1.5,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        sx={{
                          mb: "2rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "solid 2px #d5d7d8",
                          borderRadius: "0.8rem",
                          aspectRatio: [null, "16 / 9"],
                        }}
                      >
                        <GatsbyImage
                          image={library.icon.gatsbyImageData}
                          alt="Coming soon"
                          objectFit="contain"
                          sx={{
                            aspectRatio: [null, "16 / 9"],
                            height: "100%",
                          }}
                        />
                      </div>
                      <div sx={{ mt: "auto" }}>
                        <strong
                          sx={{
                            display: "inline-block",
                            mb: "0.5rem",
                          }}
                        >
                          Coming soon:
                        </strong>
                        <div
                          sx={{
                            fontSize: "1.4rem",
                            "p:last-of-type": {
                              mb: 0,
                            },
                          }}
                        >
                          {library.modules_coming_soon}
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            )}
          </Panel>
        )
      })}
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    imageSocial: file(name: { eq: "mask-social" }) {
      name
      childImageSharp {
        gatsbyImageData(width: 1200, layout: FIXED, formats: JPG)
      }
    }
    libraries: allPrismicLearningLibrary(
      sort: { fields: data___sort_order, order: ASC }
      filter: { uid: { ne: "ready" } data: {show_on_website: {eq: true}} }
    ) {
      nodes {
        data {
          title {
            text
          }
          landing_page_description {
            richText
            text
          }
          benefits {
            richText
          }
          icon {
            gatsbyImageData
          }
          modules_coming_soon
          modules {
            module {
              document {
                ... on PrismicModule {
                  data {
                    title {
                      text
                    }
                    description {
                      richText
                    }
                    category
                    thumbnail_image {
                      gatsbyImageData
                    }
                  }
                  uid
                }
              }
            }
          }
        }
        uid
      }
    }
  }
`
